@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,400&display=swap");

/* Base styles for the footer */
.footer {
  height: 500px;
  background-color: #f5f5f5;
  margin-top: 0;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.law {
  font-size: 30px;
  margin-left: 10px;
  font-family: "K2D", sans-serif;
  transition: color 0.3s ease;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.crats {
  font-size: 30px;
  font-family: "K2D", sans-serif;
  color: #949090;
  transition: color 0.3s ease;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.footer_top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.security-compliance-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.compliance-cards {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 15px;
}

.compliance-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  font-family: Arial, sans-serif;
  outline: 1px solid #D0C6C6;
  padding: 8px;
}

@media (max-width: 600px){
  .compliance-card{
    font-size: 10px;
    width: 45px;
    border-radius: 5px;
    padding: 5px;

  }
  .compliance-cards{
    justify-content: center;
    gap: 15px;
  }
}

.compliance-card p {
  color: #000;
}

.footer.dark .compliance-card p {
  color: #fff;
}

.tick-icon {
  background: green;
  padding: 2px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 30px;
  color: white;
  font-size: 15px;
  margin-right: 45px;
}

.security-text {
  font-family: Arial, sans-serif;
  color: #000;
  text-align: center;
}
.security-text p{
  margin-bottom: 20px;
}
.footer.dark .security-text{
  color: #a5a5a5;
}

.footer_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13.5px;
  padding-bottom: 15px;
}

.footer_bottom2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Playfair Display", serif;
  font-size: 12px;
  color: #333333;
  padding-bottom: 10px;
}
.footer_bottom3 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-size: 12px;
  color: #333333;
  padding-bottom: 20px;
}

.right_footer .div4 a i {
  margin-right: 10px;
}

.right_footer {
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
  gap: 190px;
}

.right_footer div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
}

.right_footer .div4 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.right_footer div h4 {
  color: #a5a5a5;
  font-weight: 500;
  margin-bottom: 8px;
}

.right_footer div a {
  color: #141414;
  font-size: 14px;
  line-height: 24px;
}

.left_footer {
  margin-top: 50px;
}

.left_footer .lo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.left_footer .lo p {
  font-size: 30px;
  font-weight: bold;
  line-height: 38.26px;
  font-family: "K2D", sans-serif;
}

.left_footer .lo img {
  width: 30px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 120px;
  width: 420px;
  padding: 10px;
  border: 1px solid #ffdbe0;
  border-radius: 16px;
}

.form p {
  font-size: 16px;
  line-height: 18px;
  padding: 10px 10px;
  margin-bottom: -10px;
  margin-top: 10px;
}

.form1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

/* .form1 input:first-of-type {
  margin-top: -15px;
}
.form1:last-of-type {
  margin-top: -10px;
} */
.form1 input {
  height: 40px;
  width: 282px;
  margin-left: 6px;
  border-radius: 8px;
  border: 1px solid #a5a5a5;
  text-align: left;
  padding: 10px;
}

.form1 button {
  padding: 2px 13px 2px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 8px;
  height: 40px;
}

.left_footer a {
  height: 40px;
  width: 40px;
}

.left_footer a i {
  font-size: 20px;
}

.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
  float: left;
}

.icon-a {
  color: #323131;
}

.face a {
  padding: 7px;
  border: 1px solid #ffdbe0;
  border-radius: 8px;
}

.face a:hover {
  background-color: #ff5733;
  transition: all 0.3s ease-in-out;
}

.footer.dark {
  background-color: #222;
}

.footer.dark .footer_bottom {
  color: #fff;
}

.footer.dark .footer_bottom2 {
  color: #fff;
}
.footer.dark .footer_bottom3 {
  color: #fff;
}

.footer.dark .right_footer div h4 {
  color: #ccc;
}

.footer.dark .right_footer div a {
  color: #ddd;
}

.footer.dark .form {
  border-color: #444;
}

.footer.dark .form1 input {
  border-color: #666;
}

.footer.dark .form1 button {
  background-color: #555;
}

.footer.dark .lo {
  color: #fff;
}

.footer.dark .form {
  color: #fff;
}

.footer.dark .icons .face i {
  color: #fff;
}

@media (max-width: 1300px) {
  .right_footer {
    gap: 80px;
  }
}

@media (max-width: 1150px) {
  .right_footer {
    gap: 10px;
  }
}

@media (max-width: 960px) {
  .footer {
    padding: 0px 20px;
  }

  .right_footer {
    gap: 3px;
  }
}

/* Responsive styles */
@media (max-width: 870px) {
  .footer {
    height: 650px;
  }

  .footer_top {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 40px; */
  }

  .right_footer {
    display: flex;
    flex-direction: column;
    gap: 100px;
  }

  .left_footer {
    order: 1;
    margin-bottom: 20px;
  }

  .right_footer {
    order: 2;
  }
  /* .footer-bottom {
    padding-bottom: 60px;
  } */
}
@media (max-width: 870px){
  .footer_bottom{
    padding-bottom: 12px;
  }
}
@media (max-width: 600px) {
  .footer {
    height: 850px;
  }

  .footer_top {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .tick-icon{
    padding: 1px;
    padding-left: 4px;
    padding-right: 4px;
    margin-right: 10px;
    font-size: 10px;
  }

  .footer_bottom2 {
    font-size: 12px;
    text-align: center;
    padding: 12px 0;
  }
  .footer_bottom3 {
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 45px;
  }

  .footer_bottom {
    font-size: 12px;
    text-align: center;
    /* padding-bottom: 60px; */
  }

  .right_footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 20px;
  }

  .right_footer > div:nth-child(3) {
    grid-column: span 2;
    justify-content: center;
    gap: 9px;
  }

  .left_footer {
    order: 1;
    margin-bottom: 20px;
  }

  .right_footer {
    order: 2;
    margin-left: 20px;
  }

  .lo {
    margin-left: 10px;
  }

  .form {
    align-items: center;
    width: 358px;
    height: 104px;
  }

  .form p {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: -120px;
  }

  .form1 input {
    width: 241px;
  }

  .form1 button {
    padding: 0px 5px;
  }

  .icons {
    margin-left: 110px;
  }
}

@media (min-width: 540px) and (max-width: 800px) {
  .footer {
    margin-bottom: 50px;
  }
}

/* @media (max-width: 540px) {
  .footer {
    margin-bottom: 50px;
  }

} */

@media (max-width: 800px) {
  .footer {
    height: auto;
    padding: 20px;
  }
  .right_footer div a {
    font-size: 14px;
  }
}
