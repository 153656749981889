@import url("https://fonts.googleapis.com/css2?family=Jomolhari&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.aboutus-container {
  text-align: center;
  padding: 20px;
  color: #333;
}

.aboutus-container.dark {
  background-color: #000000;
  color: #f5f5f5;
}

.intro-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 80px;
}

.intro-text {
  max-width: 600px;
  text-align: left;
}

.aboutus-heading {
  font-size: 3em;
  margin-bottom: 15px;
}

.aboutus-container .aboutparagraph {
  font-size: 1em;
  line-height: 1.6;
}

.intro-image-container {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.intro-image {
  width: 450px;
  height: auto;
}

.aboutus-container h2,
.aboutus-container h3 {
  margin-top: 80px;
  margin-bottom: 15px;
  color: #444;
}


.aboutus-container.dark h2,
.aboutus-container.dark h3 {
  color: #ddd;
}

.highlights-container {
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
}

.highlight-box {
  text-align: center;
  padding: 24px;
  border-radius: 10px;
  
}

.highlight-icon {
  margin-bottom: 8px;
}

.highlight-title {
  font-weight: bold;
  color: #000;
}

.aboutus-container.dark .highlight-title {
  color: #fff;
}

.highlight-description {
  color: #222;
}
.aboutus-container.dark .highlight-description {
  color: #c0bebe;
}

.founder-heading {
  font-family: "Inter", sans-serif;
  font-size: 25px;
  font-weight: 600;
}
.founder-description {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}

.team-heading {
  font-family: "Inter", sans-serif;
  font-size: 25px;
  font-weight: 600;
}

.textInfoContainer{
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.text-card-about{
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(64, 64, 64, 0.2),
    0 6px 20px 0 rgba(69, 69, 69, 0.19), 0 8px 32px 0 rgba(79, 78, 78, 0.36);
  overflow: hidden;
  width: 300px;
  padding: 20px;
  transition: transform 0.3s ease;
  margin: 30px;
}

.about-text-title{
  font-size: 18px;
  margin: 12px 0;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
}

.about-text-content{
  font-size: 12pt;
  line-height: 1.5;
  overflow-wrap: break-word;
  text-align: justify;
  text-justify: inter-word;
}

.founders-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.founder-card {
  background: linear-gradient(145deg, #f0f0f0, #e0e0e0);
  border-radius: 15px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19),
    0 8px 32px 0 rgba(0, 0, 0, 0.36);
  transition: transform 0.3s ease;
  text-align: center;
  margin: 20px;
}

.founder-card:hover {
  transform: translateY(-5px);
}

.founder-photo {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.founder-card h4 {
  margin: 10px 0;
  font-size: 1.4em;
  color: #333;
}

.founder-card p {
  color: #555;
  margin: 5px 0;
}

.founder-card a {
  color: #0073b1;
  text-decoration: none;
  font-weight: bold;
  margin: 0 8px;
}

.founder-card a:hover {
  text-decoration: underline;
}

.team-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.team-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(64, 64, 64, 0.2),
    0 6px 20px 0 rgba(69, 69, 69, 0.19), 0 8px 32px 0 rgba(79, 78, 78, 0.36);
  overflow: hidden;
  width: 220px;
  padding: 15px;
  text-align: center;
  transition: transform 0.3s ease;
  margin: 30px;
}

.team-card:hover {
  transform: scale(1.05);
}

.aboutus-container.dark .team-card {
  background-color: #333;
}

.aboutus-container.dark .team-card h4 {
  color: #f5f5f5;
}

.aboutus-container.dark .team-card p {
  color: #bbb;
}

.team-photo {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.team-card h4 {
  font-size: 1.2em;
  margin: 8px 0;
  color: #333;
}

.team-card p {
  color: #666;
  margin: 5px 0;
}

.team-card a {
  color: #0073b1;
  text-decoration: none;
  margin: 0 8px;
}

.team-card a:hover {
  text-decoration: underline;
}

.aboutus-container.dark .team-card a {
  color: #bbdefb;
}

.joinus-container {
  max-width: 700px;
  margin: 2rem auto;
  padding: 2rem;
  border-radius: 10px;
  background-color: #d3d2d2;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.aboutus-container.dark .joinus-container {
  background-color: #2c2c2c;
  color: #fff;
}

.joinus-container h4 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.aboutus-container.dark .joinus-container h4 {
  color: #f1f1f1;
}

.joinus-form .MuiGrid-item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.joinus-form .MuiTextField-root,
.joinus-form .MuiSelect-root {
  background-color: #ffffff;
  border-radius: 5px;
}

.joinus-container.dark .MuiTextField-root,
.joinus-container.dark .MuiSelect-root {
  background-color: #3b3b3b;
  color: #ffffff;
}

input[type="file"] {
  padding: 12px;
  background-color: #e9e9e9;
  border-radius: 5px;
  color: #000;
  border: 1px solid #ddd;
  font-size: 0.9rem;
}

.custom-select {
  height: 47px;
  display: flex;
  align-items: center;
}

.submit-button {
  padding: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  text-transform: none;
  background-color: #4caf50;
  color: white;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #ff5733;
}

@media (max-width: 768px) {
  .intro-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5px;
  }

  .aboutus-heading {
    font-size: 1.8em;
    margin-bottom: 10px;
    text-align: center;
  }

  .aboutparagraph {
    font-size: 1em;
  }

  .intro-text,
  .intro-image-container {
    width: 100%;
  }

  .intro-image {
    max-width: 100%;
    height: auto;
  }



  .founder-heading {
    font-size: 2rem;
  }

  .founders-container {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .founder-photo {
    height: 180px;
  }

  .founder-card h4 {
    font-size: 1.3rem;
  }

  .founder-card p {
    font-size: 0.9rem;
  }

  .founder-description {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .founders-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .founder-photo {
    height: 100px;
    height: 100px;
  }

  .founder-card h4 {
    font-size: 1.2rem;
  }

  .founder-card p {
    font-size: 0.8rem;
  }

  .founder-description {
    font-size: 0.7rem;
  }

  .founder-card a {
    font-size: 1.2rem;
  }
}
