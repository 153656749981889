/* StickyFooter.css */
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.sticky-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  /* border-top: 1px solid #ccc; */
  display: none;
  justify-content: space-around;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  z-index: 10;
}
.stickey_button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stickey_button img {
  width: 22px;
  height: 26px;
}
.stickey_button button {
  outline: none;
  border: none;
  color: #fff;
  background: transparent;
}
.sticky-footer.dark-mode {
  background-color: #fff;
}
.sticky-footer.dark-mode .stickey_button button {
  color: #000;
}

.sticky-footer.dark-mode .stickey_button img{
  filter: brightness(0);
}
@media (max-width: 800px) {
  .sticky-footer {
    display: flex;
  }
}
