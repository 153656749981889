@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Athiti:wght@200;300;400;500;600;700&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Animation for button hover */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
  }
}

.homenav_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.homenav_bar.scrolled {
  backdrop-filter: blur(10px); /* Blur effect on scroll */
}

.homenav_bar.dark {
  background-color: rgb(0, 0, 0);
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
  box-shadow: 0 5px 15px rgba(131, 129, 129, 0.25);
}

.homenavbar_left {
  display: flex;
  align-items: center;
}

.homenavbar_left h1 .law{
  font-size: 30px;
  margin-left: 10px;
  font-family: "K2D", sans-serif;
  color: #000000;
  transition: color 0.3s ease;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.homenavbar_left h1 .crats{
  font-size: 30px;
  font-family: "K2D", sans-serif;
  color: #949090;
  transition: color 0.3s ease;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
}

.homenav_bar.dark .homenavbar_left h1 .law {
  color: #ffffff;
}

.homenavbar_left img {
  width: 40px;
  height: 40px;
  transition: all 0.3s ease;
}

.homenav_bar.dark .homenavbar_left img {
  height: 40px;
  width: 40px;
  transition: all 0.3s ease;
}

.homenavbar_mid{ 
  margin-left: 130px;
}

/* .homenavbar_mid ul {
  list-style: none;
  display: flex;
  gap: 15px;
  padding: 0;
  margin: 0;
  font-family: "Athiti", sans-serif;
} */

/*  */

.homenavbar_mid ul li.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.homenav_bar.dark .homenavbar_mid ul li {
  color: #ffffff;
}

.homenavbar_mid ul li:hover,
.homenavbar_mid ul li.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.homenavbar_right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.homenavbar_right .nightlight {
  border: none;
  background: transparent;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.homenavbar_right .login {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  background-color: #000000;
  color: #ffffff;
  width: 95px;
  height: 30px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  /* transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; */
}
.homenavbar_mid .login.hide_button {
  display: none;
}

.hide_nav_button_desktop {
  display: none;
}

.homenavbar_mid .login {
  font-family: "Inter", sans-serif;
  font-size: 17px;
  background-color: #000000;
  color: #ffffff;
  width: 109px;
  height: 35px;
  border-radius: 29px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  /* transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; */
}
.homenavbar_mid .login::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.3) 0%,
    transparent 80%
  );
  transition: width 0.4s ease, height 0.4s ease;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.homenav_bar.dark .homenavbar_mid .login {
  background-color: #ffffff;
  color: #000000;
}

.homenavbar_right .login::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.3) 0%,
    transparent 80%
  );
  transition: width 0.4s ease, height 0.4s ease;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

/* .homenavbar_right .login:hover::before {
  width: 0;
  height: 0;
}

.homenavbar_right .login:hover {
  background-color: rgb(68, 67, 67);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
} */

.homenav_bar.dark .homenavbar_right .login {
  background-color: #ffffff;
  color: #000000;
}

.homenavbar_mid ul li:before,
.homenavbar_mid ul li:after {
  content: "";
  position: absolute;
  z-index: -1;
  pointer-events: none;
  top: 0;
  right: -50%;
  left: -50%;
  height: 0;
  padding-bottom: 200%;
  border-radius: 39%;
  border: unset;
  transition: transform 0s cubic-bezier(0.2, 0, 0.1, 1) 0.5s,
    opacity 0.5s cubic-bezier(0.2, 0, 0.1, 1);
  opacity: 0;
}

.homenavbar_mid ul li:before {
  transform: translate3d(-10%, 4.8em, 0) rotate(330deg);
  background: linear-gradient(25deg, #cfcbd1, rgba(216, 209, 220, 0));
}

.homenavbar_mid ul li:after {
  transform: translate3d(10%, 4.8em, 0) rotate(0deg);
  background: linear-gradient(
    70deg,
    rgba(205, 203, 207, 0.5),
    rgba(145, 142, 149, 0)
  );
}

.homenavbar_mid ul li:hover:before,
.homenavbar_mid ul li:hover:after,
.homenavbar_mid ul li:focus:before,
.homenavbar_mid ul li:focus:after {
  transition: transform 2s ease 0s, opacity 0.2s ease;
  opacity: 1;
}

.homenavbar_mid ul li:hover:before,
.homenavbar_mid ul li:focus:before {
  transform: translate3d(-10%, -1em, 0) rotate(100deg);
}

.homenavbar_mid ul li:hover:after,
.homenavbar_mid ul li:focus:after {
  transform: translate3d(10%, -1em, 0) rotate(180deg);
}
.homenavbar_mid ul {
  list-style: none;
  display: flex;
  gap: 12px;
  padding: 0;
  margin: 0;
}

.homenavbar_mid ul li {
  position: relative;
  display: inline-flex;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  color: #000000; /* Default light mode link color */
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 39px;
  overflow: hidden;
  /* transition: color 0.3s ease, background-color 0.5s ease; */
}
.menu-icons {
  display: none;
  cursor: pointer;
}
@media (max-width: 1190px) {
  .homenav_bar {
    padding: 5px 20px;
  }
  .homenavbar_mid ul {
    gap: 0px;
  }
  .homenavbar_right {
    gap: 20px;
  }
}
@media (max-width: 1030px) {
  .homenav_bar {
    padding: 5px 10px;
  }
  .homenavbar_right {
    gap: 10px;
  }
}
@media (max-width: 990px) {
  .hide_nav_button_desktop {
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 17px;
    background-color: #000000;
    color: #ffffff;
    width: 109px;
    height: 35px;
    border-radius: 29px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    /* transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;  */
  }
  
  .homenav_bar {
    padding: 5px 40px;
  }
  .homenavbar_mid .login.hide_button {
    display: block;
  }
  .homenavbar_mid ul {
    display: flex;
    flex-direction: column;
    background: #fff;
    position: absolute;
    align-items: center;
    gap: 20px;
    width: 20%;
    top: 0;
    right: -100%;
    height: auto;
    padding: 30px 0 30px 0;
    border-radius: 13px;
    margin: 0;
  }
  .nav-menu.active {
    right: 0px;
    top: 72px;
    opacity: 1;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }
  .homenavbar_right .login.hide_nav_button {
    display: none;
  }
  .nav_main ul.ulactive {
    left: 0;
    /* top: 100px; */
    opacity: 1;
    z-index: -1;
    transition: 0.3s all ease-in-out;
  }
  .nav_main ul li i {
    display: none;
  }
  .logo {
    display: none;
  }
  .menu-icons {
    display: block;
    cursor: pointer;
  }
  .homenavbar_right .nightlight {
    position: absolute;
    right: 100px;
  }
  .homenav_bar.dark .homenavbar_mid ul {
    background-color: #000;
    color: #fff;
  }
  .homenav_bar.homenav_bar.dark .menu-icons {
    color: #fff;
  }
}
.homenavbar_right.hide {
  display: none;
}
@media (max-width: 842px) {
  .homenavbar_mid ul {
    width: 30%;
    gap: 10px;
    padding: 10px 0 10px 0;
  }
}
@media (max-width: 570px) {
  .homenavbar_mid ul {
    width: 50%;
    gap: 10px;
    padding: 20px 0 20px 0;
  }
}
@media (max-width: 440px) {
  .homenav_bar {
    padding: 5px 20px;
  }
  .homenavbar_right .nightlight {
    position: absolute;
    right: 70px;
  }
}