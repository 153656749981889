.note {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0 2px 5px;
  padding: 10px;
  width: 240px;
  margin: 16px;
  float: left;
  /* margin-bottom: 30px; */
}
.note h1 {
  font-size: 1.1rem;
  /* margin-bottom: 6px; */
}
.note p {
  font-size: 1.1rem;
  margin-bottom: 10px;
  white-space: nowrap;
  word-wrap: break-word;
}
.btn {
  position: relative;
  float: right;
  margin-right: 10px;
  border: none;
  width: 50px;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-size: 16px;
}
