@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
  text-transform: none;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "Roboto", arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

.h1Header {
  font-weight: bold;
  margin: 0;
  font-size: 40px;
  color: #ffffff;
}
.h1HeaderMobi{
  font-weight: 400;
  margin: 0;
  font-size: 28px;
  color: black;
  text-align: center;
}
.h2Header {
  text-align: center;
}

.buttonClass {
  border-radius: 5px;
  border: 1px solid #454545;
  background-color: #ffffff;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.buttonClass:active {
  transform: scale(0.95);
}

.buttonClass:focus {
  outline: none;
}

.ghost {
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid #454545;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.dataSubmitForm {
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

.inputClass {
  background-color: #eee;
  border: none;
  padding: 8px 12px;
  margin: 8px 0;
  width: 80%;
  height: 50px;
  border-radius: 10px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

.container {
  background-color: #fff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  min-height: 100vh;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  align-items: center;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  align-items: center;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

.login-logo .login-law{
  font-size: 30px;
  margin-left: 10px;
  font-family: "K2D", sans-serif;
  transition: color 0.3s ease;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.login-logo .login-crats{
  font-size: 30px;
  font-family: "K2D", sans-serif;
  color: #949090;
  transition: color 0.3s ease;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.common-mv-login-signup p{
  font-size: 14px;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}
.overlay button {
  background-color: #000000;
  color: #ffffff;
}
.overlay h1 {
  color: #000000;
}
.overlay span {
  color: #000000;
}
.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  color: #070707;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 50px;
  width: 50px;
  background-color: #ffffff;
  color: #000000;
}

.paragraph {
  color: #000000;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
}
@media (max-width: 650px) {
  .container.login_hide {
    display: none;
  }
  .login-container.below850 {
    display: flex;
    width: 100%;
  }
}

.login-container1 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 20px;
  gap: 30px;
  position: relative;
  z-index: 1000;
}

.login-logo1 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 20px;
  left: 20px;
}

.login-logo1 .law {
  font-family: "K2D", sans-serif;
  font-size: 30px;
  color: white;
  transition: color 0.3s ease;
  /* text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5); */
}
.login-logo1 .crats {
  font-family: "K2D", sans-serif;
  font-size: 30px;
  color: #949090;
  transition: color 0.3s ease;
  /* text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5); */
}

.login-logo1 img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding: 20px;
  display: none;
  gap: 30px;
}
.login-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.login-logo h1 {
  font-family: "K2D", sans-serif;
}
/* Logo styling */
.login-logo img {
  width: 80px !important;
  height: 70px;
  margin-bottom: 20px;
}

.common-mv-login-signup{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.common-mv-input {
  width: 100%;
  border: 1px solid #d9d9d9;
  outline: none;
  padding: 10px 20px;
  border-radius: 8px;
}

.login_below_box {
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-radius: 22px;
  padding: 10px 30px;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}
.login_top {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.login-popup-inputs {
  display: flex;
  width: 100%;
  flex-direction: column;
  /* gap: 30px; */
}
.login_bot_width {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.login_bot_width button:first-of-type {
  padding: 8px 0px;
  width: 50%;
  outline: none;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: #000 !important;
  color: #fff;
}
.login_bot_width button:last-of-type {
  padding: 8px 40px !important;
  background-color: #000;
  color: #fff;
  border-radius: 8px;
  width: 50%;
  outline: none;
  border: none;
}
.login_bot_width span {
  color: blue;
}
