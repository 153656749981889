@import url("https://fonts.googleapis.com/css2?family=Jomolhari&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.case-search-main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #faf9f6;
}

.case-search-secondary-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.case-search-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 22px;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  color: #585858;
}

.case-search-nav .case-search-nav-img {
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.case-search-med-items {
  display: flex;
  justify-content: center;
  margin: 25px;
  position: relative;
  width: 100%;
}

.case-search-med-items .case-search-input {
  display: flex;
  align-items: center;
  background-color: #f0f4f9;
  padding: 10px 20px;
  border-radius: 10px;
  height: 50px;
  width: 50vw;
  border: 2px solid #d6d9d7;
  outline: none;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease;
}

.spcl-input {
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  cursor: pointer;
  z-index: 4;
}

.case-search-btn {
  background: #ffffff;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 12px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  position: fixed;
  bottom: 20px;
}

.case-search-btn:hover {
  background: #c4c7c5;
}

.case-search-heading1 {
  font-size: 42px;
  font-weight: 700;
  font-family: Montserrat, sans-serif;
  color: #a8aba9;
}

.case-search-heading2 {
  font-size: 26px;
  font-weight: 400;
  font-family: Montserrat, sans-serif;
  color: #a8aba9;
}

.case-search-heading {
  margin-top: 100px;
  font-size: 80px;
  font-weight: 700;
  font-family: Montserrat, sans-serif;
  color: #ffb169;
}

@media (max-width: 600px) {
  .case-search-med-items .case-search-input {
    width: 80vw;
    margin-right: -30px;
  }

  .case-search-secondary-container {
    margin-top: 40px;
  }

  .case-search-heading1 {
    font-size: 20px;
  }

  .case-search-heading2 {
    font-size: 18px;
  }

  .case-search-heading {
    margin-top: 100px;
    font-size: 35px;
  }
}
