@import url("https://fonts.googleapis.com/css2?family=Jomolhari&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

/* Styles for HomeHero */
.homehero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 60px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.7);
  transition: background-color 0.3s ease;
}

.homehero.dark {
  background-color: #222;
  /* background-color: rgba(0, 0, 0, 0.1); */
}

.homehero_content {
  text-align: center;
  color: white;
  z-index: 1;
  padding-top: 50px;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.fixed-text {
  font-family: "Jomolhari", serif;
  font-size: 55px;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 10px;
}

.homehero_content .heading {
  font-family: "Jomolhari", serif;
  font-size: 55px;
  font-weight: 400;
  line-height: 96px;
  color: #000;
  margin-top: 20px;
}

.homehero.dark .homehero_content .heading {
  color: #fff;
}

.homehero_content .content {
  color: #000;
  width: 850px;
  font-family: "Inter", sans-serif;
  margin: 0 auto;
  font-size: 21px;
  font-weight: 300;
  line-height: 27px;
  margin-top: 20px;
}

.homehero.dark .homehero_content .content {
  color: #fff;
}

.homehero_content img {
  margin-top: 40px;
  width: 100px;
  height: 100px;
}


.homehero_bot {
  position: relative;
  z-index: 1;
}

.homehero_bot_button {
  width: 237px;
  height: 55px;
  border-radius: 59px;
  outline: none;
  border: none;
  background-color: #000;
  color: #fff;
  font-size: 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  cursor: pointer;
  letter-spacing: 3px;
  font-family: "Inter", sans-serif;
  transition: background-color 0.3s, color 0.3s;
}

.homehero_bot_button:hover {
  background-color: #ff5733;
  color: #000;
}

.hh-button-div{
  font-family: "Inter", sans-serif;
  font-size: 25px;
  font-weight: 600;
  margin-top: 50px;
}

.hh-parent-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.homehero.dark .homehero_bot_button {
  color: #000;
  background-color: #ffffff;
}

.homehero.dark .hh-button-div {
  color: #fff;
}
.homehero .t1{
  width: 140px;
  height: 65px;
}
.homehero .t2{
  width: 160px;
  height: 48px;
}
.homehero .t4{
  width: 190px;
  height: 50px;
}
.homehero .t6{
  width: 150px;
  height: 50px;
}
.homehero .t8{
  width: 130px;
  height: 60px;
}
.homehero .t9{
  width: 130px;
  height: 50px;
}
.homehero .t11{
  width: 80px;
  height: 60px;
}
.homehero .t13{
  width: 50px;
  height: 55px;
}
.homehero .t14{
  width: 90px;
  height: 55px;
}
.homehero.dark .t1 {
  filter: brightness(1) invert(1) !important;
}
.homehero.dark .t2 {
  filter: brightness(1) invert(1) !important;
}
.homehero.dark .t3 {
  filter: brightness(1) invert(1) !important;
}
.homehero.dark .t4 {
  filter: brightness(0) invert(1) !important;
}
.homehero.dark .t5 {
  filter: brightness(0) invert(1) !important;
}
.homehero.dark .t6 {
  filter: brightness(1) invert(1) !important;
}
.homehero.dark .t7 {
  filter: brightness(1) invert(1) !important;
}
.homehero.dark .t9 {
  filter: brightness(1) invert(1) !important;
}
.homehero.dark .t11 {
  filter: brightness(1) invert(1) !important;
}
.homehero.dark .t12 {
  filter: brightness(1) invert(1) !important;
}

.logo-container {
  margin-top: 25px;
  overflow: hidden;
  width: 100%;
  position: relative;
}

.logo-slider {
  display: flex;
  animation: scroll 60s linear infinite;
  width: max-content;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}
.logo-item {
  flex: 0 0 auto;
  padding-right: 50px;
}

.logo-item img {
  height: 50px;
}

@media (max-width: 1200px) {
  .homehero {
    /* height: 80vh; */
  }
}
@media (max-width: 1170px) {
  .homehero {
    /* height: 70vh; */
  }
}
@media (max-width: 820px) {
  .homehero_content img {
    width: 120px; /* Set a smaller width for smaller screens */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Ensure it's not hidden */
    margin: 25px auto 20px; /* Center it and add some margin */
  }

  .homehero_content .content {
    font-size: 15px; /* Slightly larger font for readability */
    line-height: 24px; /* Adjust line-height */
    width: 70%; /* Ensure the content fits properly */
  }

  .homehero_content .heading {
    font-size: 10px;
    margin-top: 0px;
  }

  .homehero_bot_button {
    width: 150px; /* Reduce button width */
    height: 45px; /* Adjust height */
    font-size: 18px; /* Adjust font size */
  }
}

@media (max-width: 600px) {
  .homehero_content .content {
    font-size: 20px;
    width: 50%;
    margin-top: -10px;
    line-height: 20px;
  }
  .homehero_content .heading {
    text-align: center;
    margin-left: 220px;
  }
  .homehero_content .heading span {
    font-size: 35px;
  }
  .homehero_bot_button {
    width: 167px;
    height: 40px;
    font-size: 20px;
  }
  .homehero .bg-video {
    top: -70px;
  }
}
@media (max-width: 480px) {

  .hh-parent-div .hh-button-div {
    font-size: 20px;
  }

  .logo-container {
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 20px;
  }

  .logo-item img {
    height: 40px;
  }

  /* .homehero .bg-video {
    top: -70px;
    height: 500px;
  } */
   .homehero{
    padding-bottom: 20px;
   }
  .homehero_content img {
    width: 100px;
    height: auto;
    display: block;
  }
  .homehero_content .heading {
    text-align: center;
    margin-left: 200px;
  }
  .homehero_content .heading span {
    font-size: 25px;
  }
  .homehero_content .content {
    font-size: 13px;
    width: 350px;
    margin-top: -10px;
    line-height: 20px;
  }
  .homehero_bot_button {
    width: 120px;
    height: 40px;
    font-size: 14px;
  }
  .homehero.dark .homehero_content img {
    width: 100px;
    height: auto;
  }

  .fixed-text {
    margin-right: 0px;
  }
}
