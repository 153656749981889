.navbar {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  align-items: center;
  z-index: 999;
  position: fixed;
  top: 0;
  padding: 0 20px;
  text-decoration: none;
}

.nav_items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.logo {
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
}

.logo img {
  width: 25px;
}

.logo h1 {
  font-size: 25px;
  text-decoration: none;
  color: #000;
}

.nav_main ul {
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: #000;
}

.nav_main ul li {
  font-size: 13px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  font-weight: 600;
  /* transition: all 0.3s ease; */
  color: #000;
}

.nav_main ul li.active {
  font-weight: bold;
  color: #333; /* Example of darker color */
  color: #000;
}

ul li i {
  margin-left: 3px;
}

.nav_main ul li i:hover {
  transition: all 0.5s ease-in-out;
  transform: translateY(2px);
}

.nav_side {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  font-size: 18px;
}

.cart img {
  margin-top: 5px;
  width: 22px;
}

@media (max-width: 970px) {
  .navbar {
    padding: 0 10px;
    z-index: 999;
  }

  .logo {
    gap: 5px;
  }

  .nav_side {
    gap: 20px;
  }

  .nav_main ul {
    gap: 15px;
  }
}
.menu-icons {
  display: none;
}
@media (max-width: 1000px) {
  .nav_items {
    width: 100%;
    gap: 2px;
    padding: 10px;
  }
}
@media (max-width: 870px) {
  /* Add responsive CSS if needed */
  .menu-icons {
    display: block;
    font-size: 25px;
    order: 3;
  }
  .nav_items {
    width: 100%;
    gap: 2px;
    padding: 10px;
    justify-content: space-between;
  }
  .nav_main ul {
    display: flex;
    flex-direction: column;
    background: #fff;
    position: absolute;
    align-items: center;
    gap: 50px;
    width: 100%;
    top: 0;
    left: -100%;
    height: auto;
    padding: 80px 0 30px 0;
    border-radius: 13px;
    margin: 0;
  }
  .nav_main ul.ulactive {
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.3s all ease-in-out;
  }
  .nav_main ul li i {
    display: none;
  }
}
