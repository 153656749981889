@import url("https://fonts.googleapis.com/css2?family=Jomolhari&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.case-search-main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #FAF9F6;
}

.case-search-secondary-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.case-search-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 22px;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  color: #585858;
}

.case-search-nav .case-search-nav-img {
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.case-search-med-items {
  display: flex;
  justify-content: center;
  margin: 25px;
  position: relative;
  width: 100%;
}

.case-search-med-items .case-search-input {
  display: flex;
  align-items: center;
  background-color: #f0f4f9;
  padding: 10px 20px;
  border-radius: 10px;
  height: 50px;
  width: 50vw;
  border: 2px solid #d6d9d7;
  outline: none;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease;
}


/* style={{ position: 'absolute', right: "26%", top: '50%', transform: 'translateY(-50%)', padding: '0', cursor: 'pointer', zIndex: '4' }} */
.spcl-input {
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  cursor: pointer;
  z-index: 4;
}

.case-search-btn {
  background: #ffffff;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 12px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  position: fixed;
  bottom: 20px;
}

.case-search-btn:hover {
  background: #c4c7c5;
}

.case-search-heading1 {
  font-size: 32px;
  font-weight: 700;
  font-family: Montserrat, sans-serif;
  color: #a8aba9;
}

.case-search-heading2 {
  font-size: 24px;
  font-weight: 400;
  font-family: Montserrat, sans-serif;
  color: #a8aba9;
}

.case-search-small-card {
  width: 70vw;
  max-height: 56vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 5px 0;
  gap: 20px;
  scrollbar-width: thin;
  margin-top: 20px;
  margin-bottom: 20px;
}

.case-search-small-card::-webkit-scrollbar {
  width: none;
  /* Adjust scrollbar width */
}

.case-search-small-card::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Customize the scrollbar color */
  border-radius: 10px;
}

.smallCard {
  background-color: #f0f4f9;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* align-items: center; */
  text-align: left;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  box-shadow: #585858;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.onHoverSpan {
  border-radius: 4px;
  padding: 3px;
}

.onHoverSpan:hover {
  background-color: #c4c7c5;
}

.generated-summary-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* Ensure content aligns to the top */
  align-items: center;
  color: black;
  padding: 12px;
  max-height: calc(90vh - 110px);
  /* Restrict the max height */
  overflow-y: auto;
  flex-grow: 1;
  white-space: normal;
  word-wrap: break-word;
  line-height: 2.3rem;
  word-spacing: 0.05em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: "18px";
}

.hrLeftCS{
  flex: 1;
  border: 0;
  border-top: 1px solid grey;
  margin-right: 10px;
}

.hrRightCS{
  flex: 1;
  border: 0;
  border-top: 1px solid grey;
  margin-left: 10px;
}

.centeredHeaderLineBothSideCS{
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.case-search.dark-mode .case_search_top .case_search_heading {
  color: #fff;
}

.case-search.dark-mode .case_search_p p {
  color: #fff;
}

.case-search.dark-mode .case_search_box_top {
  color: #fff;
}

.case-search.dark-mode .case_search_box_med p {
  color: #fff !important;
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background-color: #f0f4f9;
  padding: 10px 0px;
  border-radius: 10px;
  height: 50px;
}

.case-search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  background-color: #f0f4f9;
  padding: 10px 20px;
  border-radius: 10px;
  height: 50px;
  width: 50%;
  margin-top: 20px;
}

.case-search-box input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  padding: 8px;
  font-size: 18px;
}

@media (max-width: 600px) {
  .case-search-med-items .case-search-input {
    width: 80vw;
    margin-right: -30px;
  }

  /* .case-search-secondary-container {
    margin-top: 40px;
  } */

  .case-search-small-card {
    width: 70vw;
    display: flex;
    flex-direction: column;
    /* Change direction to vertical */
    overflow-y: auto;
    /* Enable vertical scrolling */
    max-height: 600px;
    /* Optional: Limit the height of the container */
    padding: 5px 0;
    gap: 20px;
    /* Adjust the gap between the cards */
    scrollbar-width: thin;
    /* For Firefox: show thin scrollbar */
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .case-search-heading1 {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .case-search-heading2 {
    font-size: 18px;
  }

  .case-search-box {
    width: 100%;
    padding: 8px;
  }

  .case-search-box input {
    font-size: 14px;
  }

  .search-box {
    width: 100%;
    padding: 8px;
    gap: 0px;
  }

  .search-box input {
    font-size: 14px;
  }
}