.home-testimonial {
  padding: 20px;
  position: relative;
  background-color: #f8f8f8;
  background: radial-gradient(
    circle,
    rgb(250, 239, 239) 0%,
    rgb(240, 241, 242) 100%
  );
  top: -1;
}

.input-container {
  position: relative;
  width: 100%; 
}


/* .input-container input {
  width: 100%;   
  padding-right: 100px;  
  box-sizing: border-box;
  padding-left: 10px; 
} */

.login {
  font-family: "Inter", sans-serif;
  font-size: 17px;
  width: 109px;
  height: 35px;
  border-radius: 29px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.input-icons {
  position: absolute;
  top: 50%;
  right: 80px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 10px; 
}

.mic-icon, .send-icon {
  cursor: pointer;
  font-size: 20px; 
}

.send-icon {
  transform: rotate(0deg); 
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  font-size: 20px; 
  color: #000000; 
}

.mic-icon {
  transition: color 0.3s ease;
}

.mic-icon.recording {
  color: red; /* Optional: Change color while recording */
}

/* Light theme colors */
.mic-icon, .send-icon {
  color: black; /* Default color in light mode */
}

/* Dark theme adjustments */
.home-testimonial.dark-mode .mic-icon,
.home-testimonial.dark-mode .send-icon {
  color: white; /* Change icon color in dark mode */
}

.recent-case-studies {
  /* width: 541px; */
  /* height: 68px; */
  margin: 0 auto;
  text-align: center;
  color: #000000;
  /* padding: 10px; */
  border-radius: 10px;
}

.recent-case-studies h2 {
  font-family: "Inter", sans-serif;
  font-size: 34px;
  font-weight: 700;
  /* line-height: 58.54px; */
  letter-spacing: 0.04em;
}

.feedback {
  width: 586px;
  height: 69px;
  margin: 30px auto;
  color: #000000;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.feedback h3 {
  font-family: "K2D", sans-serif;
  font-size: 45px;
  font-weight: 500;
  line-height: 68.94px;
  letter-spacing: 0.04em;
}

.ask-for-any-case {
  width: 1040px;
  height: 220px;
  margin: 0px auto;
  padding: 20px;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ask-for-any-case input {
  width: 90%;
  height: 80px;
  padding-left: 20px;
  padding-right: 90px;
  border: 2px solid #363434;
  border-radius: 25px;
  background: #ffffff;
  color: #000000;
  font-family: "K2D", sans-serif;
  font-size: 18px;
  line-height: 22px;
  outline: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
}

.ask-for-any-case input::placeholder {
  color: #cccccc;
}

.ask-for-any-case input:focus {
  border-color: rgba(223, 207, 207, 0.5);
  box-shadow: 0px 4px 20px #aba3a3;
}

.login-message {
  position: absolute;
  margin-top: 30px;
  top: 110px;
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: bold;
  display: none;
  background-color: #f1f1f1;
  padding: 10px 20px;
  border-radius: 10px;
  max-width: 300px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  z-index: 1;
}

.login-message.active {
  display: block;
}

.login-message::after {
  content: "";
  position: absolute;
  bottom: -10px; /* Position the triangle */
  left: 20px; /* Align it to the left of the message box */
  border-width: 10px;
  border-style: solid;
  border-color: #f1f1f1 transparent transparent transparent; /* Creates a triangle effect */
}

.testimonial-carousel {
  /* width: 100%; */
  margin: 0 auto;
  position: relative;
  padding: 20px;
}

.testimonial-card {
  width: 360px;
  max-width: 360px;
  height: 300px;
  border-radius: 20px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  overflow: hidden;
  background: rgb(206, 187, 198);
  background: radial-gradient(
    circle,
    rgb(188, 220, 207) 0%,
    rgb(227, 232, 238) 100%
  );
}

.new-visit {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #000000;
  padding: 5px 10px;
  border-radius: 20px;
  font-family: "K2D", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.feedback-text {
  font-family: "K2D", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 20px;
}

.quote-icon1 {
  margin-top: 20px;
  font-size: 10px;
  color: #000000;
}

.quote-icon2 {
  font-size: 24px;
  color: #000000;
}

.card-footer {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.circle-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #e0e0e0;
  overflow: hidden;
  margin-right: 10px;
}

.circle-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-footer .name {
  font-family: "K2D", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.card-footer .visit-link {
  font-family: "K2D", sans-serif;
  font-size: 14px;
  color: #d6801b;
  text-decoration: underline;
  margin-left: 10px;
}

/* .logo-container {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
  gap: 40px;
  flex-wrap: wrap;
}

.logo-item img {
  width: 220px;
  height: auto;
} */

/* .parent-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
} */

.button-div {
  padding: 8px 16px;
  color: rgb(0, 0, 0);
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 28px;
  font-weight: 700;
}

.button-div:hover {
  border-color: #000000;
}

/* Dark mode for the entire testimonial section */
.home-testimonial.dark-mode {
  background: rgba(0, 0, 0, 1);
  background: radial-gradient(#333333, rgba(19, 19, 19, 0.7)),
    rgba(255, 83, 83, 0);
}

/* Dark mode for specific cards */
.testimonial-card.dark-card {
  background-color: #333333;
  color: #ffffff;
}

/* Dark mode for input field */
.home-testimonial.dark-mode .ask-for-any-case input {
  background-color: #333333;
  color: #ffffff;
  border-color: #555555;
}
.home-testimonial.dark-mode .recent-case-studies h2 {
  color: #ffffff;
}

.home-testimonial.dark-mode .ask-for-any-case input::placeholder {
  color: #aaaaaa;
}
.home-testimonial.dark-mode .feedback h3 {
  color: #ffffff;
}
/* Dark mode for button */
.home-testimonial.dark-mode .button-div {
  color: #ffffff;
  border-color: #ffffff;
}

.home-testimonial.dark-mode .login-message {
  background-color: #333333;
  color: #ffffff;
}

.home-testimonial.dark-mode .testimonial-card .visit-link {
  color: #e0aaff;
}

.testimonial-card.dark-card {
  background-color: #c08a7c;
  background: radial-gradient(
    circle,
    rgb(165, 140, 140) 0%,
    rgb(61, 61, 61) 100%
  );
}

.testimonial-card .dark-card .new-visit {
  color: #ffffff;
}
.testimonial-card .dark-card .quote-icon1 {
  color: #ffffff;
}

.testimonial-card .dark-card .quote-icon2 {
  color: #ffffff;
}
@media (max-width: 800px) {
  .home-testimonial {
    display: none;
  }
}
@media (max-width: 1300px) {
  .testimonial-card {
    width: 330px !important;
  }
}
@media (max-width: 1110px) {
  .testimonial-card {
    width: 300px !important;
  }
}
@media (max-width: 1030px) {
  .ask-for-any-case input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 70px !important;
    /* margin: 0 auto !important; */
  }
  .testimonial-card {
    width: 250px !important;
  }
}
@media (max-width: 980px) {
  .ask-for-any-case input {
    margin-right: 100px !important;
  }
}
@media (max-width: 950px) {
  .ask-for-any-case input {
    margin-right: 150px !important;
  }
}
@media (max-width: 920px) {
  .ask-for-any-case input {
    margin-right: 180px !important;
  }
}
@media (max-width: 880px) {
  .ask-for-any-case input {
    margin-right: 210px !important;
  }
  .testimonial-card {
    width: 220px !important;
    height: 250px !important;
  }
}
@media (max-width: 840px) {
  .ask-for-any-case input {
    margin-right: 240px !important;
  }
}
@media (max-width: 810px) {
  .ask-for-any-case input {
    margin-right: 280px !important;
  }
}
