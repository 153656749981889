.word {
  display: inline-block;
  animation: slide 1.5s ease-in-out infinite;
  /* color: #000;  */
  color: #ff5733;
  font-family: "Jomolhari", serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 96px;
  width: 340px;
  text-align: left;
  overflow: hidden;
  display: inline-block;
  margin-left: 5px;
}

.homehero.dark .homehero_content .heading .word {
  color: #ff5733;
}

@keyframes slide {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  20% {
    transform: translateY(0);
    opacity: 1;
  }

  80% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}