/* Existing styles */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.main {
  flex: 1;
  min-height: 100vh;
  padding-bottom: 15vh;
  position: relative;
  background-color: #faf9f6;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 22px;
  padding: 25px;
  color: #585858;
}

.nav img {
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.back-button {
  display: flex;
  position: absolute;
  top: 30px;
  left: 70px;
  color: black;
  padding: 5px 10px;
  background-color: #cfcfcf;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
}



.nav_item {
  height: 200px;
  width: 200px;
  background-color: #fff;
  display: none;
  top: 60px;
  right: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.nav_item.visible {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-btn {
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
}

.toggle-btn.on {
  background-color: #28a745;
}

.toggle-btn.off {
  background-color: #dc3545;
}

.main-container {
  max-width: 900px;
  max-height: 70vh;
  margin: auto;
  overflow-y: scroll;
}

.main-container::-webkit-scrollbar {
  display: none;
}

.main .greet {
  font-size: 56px;
  color: #a8aba9;
  font-weight: 500;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main .greet img {
  width: 10%;
}

.greet .intro1 {
  font-size: 32px;
  font-weight: 700;
  font-family: Montserrat, sans-serif;
}

.greet .intro2 {
  font-size: 24px;
  font-weight: 400;
  font-family: Montserrat, sans-serif;
}

.main .cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 15px;
  padding: 20px;
}

.main .card {
  height: 200px;
  padding: 15px;
  background-color: #f0f4f9;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.result {
  padding: 0px 5%;
  font-size: 16px;
  color: black;
  line-height: 2.3rem;
  word-spacing: 0.05em;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: "18px";
}

.result-title {
  margin: 40px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  background-color: #f0f4f9;
  padding: 10px 0;
  border-radius: 10px;
}

.result-response-part {
  display: flex;
  flex-direction: row;
  margin: 40px 0;
  border-radius: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

.imgClassBot {
  flex-shrink: 0;
  width: 50px;
  padding: 5px;
}

.formatted-content-wrapper {
  margin-left: 10px;
  flex-grow: 1;
}

.imgClassUser {
  flex-shrink: 0;
  width: 50px;
  padding: 5px;
  border-radius: 50%;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.main .card p {
  color: #585858;
  font-size: 17px;
}

.main .card:hover {
  background-color: #dfe4ea;
}

.main-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 900px;
  padding: 0px 20px;
  margin: auto;
}

.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background-color: #f0f4f9;
  padding: 10px 20px;
  border-radius: 10px;
  height: 50px;
}

.search-box img {
  width: 24px;
  cursor: pointer;
}

.search-box input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  padding: 8px;
  font-size: 18px;
  padding: 10px 20px;
}

.search-box div {
  display: flex;
  align-items: center;
  gap: 15px;
}

.main .bottom-info {
  font-size: 13px;
  margin: 15px auto;
  font-weight: 300;
  text-align: center;
}

.loader {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loader hr {
  border-radius: 4px;
  border: none;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #9ed7ff, #ffffff, #9ed7ff);
  background-size: 800px 50px;
  height: 20px;
  animation: loader 3s infinite linear;
}

@keyframes loader {
  0% {
    background-position: -800px 0px;
  }

  100% {
    background-position: 800px 0px;
  }
}

.result-data p {
  font-size: 17px;
  font-weight: 300;
  line-height: 1.8;
}

@media (max-width: 600px) {
  .main-bottom input {
    flex: none;
    width: 75%;
  }

  .back-button {
    top: 30px;
    left: 20px;
    font-size: 10px;
  }

  .back-button svg {
    width: 14px;
    height: 14px;
  }

  .greet .intro1 {
    font-size: 22px;
  }

  .greet .intro2 {
    font-size: 20px;
  }

  .main-bottom img {
    width: 20px;
  }

  .search-box {
    padding: 5px;
    gap:0px;
  }

  .search-box input {
    flex: 1 1;
    border: none;
    outline: none;
    background: transparent; 
    font-size: 14px;
  }

  .search-box div {
    gap: 5px;
  }

  .result {
    padding: 0px 5%;
    font-size: 14px;
    color: black;
    line-height: 1.5rem;
    word-spacing: 0.05em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: "18px";
  }

  .result-response-part {
    font-size: 14px;
    color: black;
    line-height: 1.5rem;
    word-spacing: 0.05em;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
}