@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900&display=swap");

.card-container-ha {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-wrap: wrap;
  margin-bottom: 0;
  position: relative;
}

.dark-mode {
  background-color: #000; 
}

.dark-mode .video-section video {
  border: 2px solid #ffffff;
}

.video-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
  width: 100%;
}

video {
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  width: 80%;
  height: auto;
}

/* Responsive media queries */

@media (max-width: 1120px) {
  /* .card {
    width: 280px;
    margin: 5px;
    height: 230px;
  } */
}

@media (max-width: 820px) {
  /* .card-container-ha {
    background-image: none;
    justify-content: space-around;
  } */

  /* .card {
    width: 220px;
    height: 180px;
    margin-top: 10px;
  } */

  /* .card-container-ha.dark-mode {
    background-image: none;
  } */

}

@media (max-width: 500px) {

  /* .card-container-ha {
    margin-top: -20px;
    margin-bottom: 50px;
    height: 100px;
    justify-content: space-around; 
    flex-wrap: nowrap;
  } */

  /* .card {
    width: 160px;
    height: 130px;
    padding: 10px;
  }

  .title {
    font-size: 14px;
    margin-top: -14px;
  }

  .icon {
    width: 30px;
  } */

  video {
    text-align: center;
    width: 300px;
  }

}